export {scrollTo, numberWithSpaces, getPhotoPlaceholder, add20ToDate, generateTextAge};
// dynamic avatars
import photoManDefault_1 from "@/assets/img/docs/placeholders/man_1.png";
import photoManDefault_2 from "@/assets/img/docs/placeholders/man_2.png";
import photoManDefault_3 from "@/assets/img/docs/placeholders/man_3.png";
import photoManDefault_4 from "@/assets/img/docs/placeholders/man_4.png";
import photoWomanDefault_1 from "@/assets/img/docs/placeholders/woman_1.png";
import photoWomanDefault_2 from "@/assets/img/docs/placeholders/woman_2.png";
import photoWomanDefault_3 from "@/assets/img/docs/placeholders/woman_3.png";
import photoWomanDefault_4 from "@/assets/img/docs/placeholders/woman_4.png";

function getPhotoPlaceholder(name, gender = "woman") {
    const avatarPlaceholders = {
        photoManDefault_1,
        photoManDefault_2,
        photoManDefault_3,
        photoManDefault_4,
        photoWomanDefault_1,
        photoWomanDefault_2,
        photoWomanDefault_3,
        photoWomanDefault_4,
    };

    const num = (name?.length % 4) + 1;
    const capitalizeGender = gender[0].toUpperCase() + gender.slice(1, Infinity);

    const photoKey = `photo${capitalizeGender}Default_${num}`;

    return avatarPlaceholders[photoKey];
}

function scrollTo(id, parent = window, minus = 0) {
    // console.log("scrollTo", id, parent, minus);

    setTimeout(() => {
        const element = document.getElementById(id);
        if (!element) return;
        if (parent !== window) {
            element.scrollIntoView({behavior: "smooth"});
            return;
        }

        function top(el, parent) {
            const rect = el.getBoundingClientRect(),
                scrollTop = parent.pageYOffset || document.documentElement.scrollTop;
            return rect.top + scrollTop;
        }

        parent.scrollTo({
            top: top(element, parent) - minus,
            behavior: "smooth",
        });
    }, 200);
}

function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function add20ToDate(inputString) {
    if (/^\d{2}\.\d{2}\.\d{2}$/.test(inputString)) {
        const parts = inputString.split('.');
        if (parts[2] < (new Date().getFullYear() - 2000)) {
            parts[2] = '20' + parts[2];
        } else
            parts[2] = '19' + parts[2];
        return parts.join('.');
    } else {
        return inputString;
    }
}


function generateTextAge(age_from, age_to) {
    // Проверяем, что значения age_from и age_to не отрицательны и не превышают 150 (примерное максимальное значение возраста человека)
    if (age_from < 0 || age_to < 0 || age_from > 150 || age_to > 150 || age_to < age_from) {
        return '';
    }

    let fromText;
    switch (age_from) {
        case 0:
            fromText = '';
            break;
        case 1:
            fromText = ' с 1 года';
            break;
        default:
            fromText = ` с ${age_from} лет`;
    }

    let toText = age_to === 17 ? ` до 18 лет` : ` до ${age_to} лет`;


    if (age_from >= 18 && !age_to) {
        return `Прием взрослых${fromText}`;
    } else if (age_to >= 18) {
        if (age_from === 18)
            return `Прием взрослых${fromText}`;
        return `Прием взрослых и детей${fromText}`;
    } else {
        return `Прием детей${fromText}${toText}`;
    }
}
